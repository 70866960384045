.container {
  padding: 50px 0px;
  text-align: center;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 30px;
  background-color: var(--bg-clr);
  min-height: 85vh;
}

.heading {
  margin: 0 auto;
  max-width: 500px;
  color: var(--head-clr);
  font-size: 3.1rem;
  line-height: 4.1rem;
  font-weight: bold;
  letter-spacing: 1px;
   
}

.heading span {
  background-clip: text;
  background-image: linear-gradient(to right, #239ce2, #1369b9);
  color: transparent;
 
}

@keyframes cursor {
  50% { border-color: transparent}
}

@keyframes typing {
  from { width:0} 
  to {width:100%}
}

.right img {
  width:400px;
}
