.welcome{
    display: flex;
    align-items: center;

    .titleL{
        flex: 1;
        padding-left: 10px;
        .titleM{
            margin-top: 50px;
            
            justify-content: start;
            font-weight: bolder;
            background: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 35px;
            margin-bottom: 20px;
            padding-right: 100px;
        }
        .spaced{
            font-weight: bold;
            font-size: 22px;

            .animat{
                color: blue;
            }
        }
    }
   
    // .spaced{
        
    //     border: 2px solid #F4F4F4;
    //     height:50vh;
    // }
    .MyLogo{
        
        padding-left: 15vw;
        flex: 1;
        margin-bottom: 300px;
        width: 30vw;
        height: 240px;
    }

}