:root{
    --main-color:rgba(33, 24, 24, 0.5);
    --nav-color: rgba(33, 24, 24, 0.9);
 
 }


 .link{
    color: inherit;
    text-decoration: none;
 }

 .dark-theme {
  --border-clr: rgba(255, 255, 255, 0.5);
   --head-clr:#fff;
   --bg-clr:#111b20;
 }

 .light-theme {
   --head-clr:#000;
   --border-clr: rgba(0,0,0,0.2);
   --bg-clr:rgb(238, 252, 255);
 }